import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { StatusService } from '../services/status.service';
import { AuthService } from '../services/auth.service';
import { SocketImportService } from '../services/socket-import.service';

import {
  selectProfil,
  selectProfilError,
  selectProfilLoading,
  selectProfilRoles,
} from '../views/users/+profil/profil.selectors';
import { ProfilActions } from '../views/users/+profil/profil.actions';

import {
  selectTelecontrols,
  selectTelecontrolsError,
  selectTelecontrolsLoading,
} from '../views/telecontrols/+store/telecontrol.selectors';
import { TelecontrolActions } from '../views/telecontrols/+store/telecontrol.actions';

import { LayoutService } from '../shared/layout.service';
import { AssessmentStoreService } from '../services/assessment.service';
import { AssessmentSyncService } from '../services/assesmentsync.service';
import { AssesmentSocketService } from '../services/assesmentsocket.service';
import { MqttMessagesSocketService } from '../services/mqtt-messages-socket.service';
import { SocketMqttPresetsService } from '../services/mqtt-presets-socket.service';
import { MqttMessage } from '../models/MqttMessage';
import { MqttMessagesService } from 'src/app/services/mqtt-messages-service.service';
import { MqttPresetsService } from 'src/app/services/mqtt-presets-service.service';
import { MqttMessagesDataService } from '../services/mqtt-messages-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  clrVerticalNavCollapsed = false;

  update = false;

  fernwirktechnik = false;
  info = { Timestamp: '', Messages: [] } as MqttMessage;
  warn = { Timestamp: '', Messages: [] } as MqttMessage;
  alarm = { Timestamp: '', Messages: [] } as MqttMessage;

  // FIXME: loading$ und error$ - werden diese angezeigt?
  profil$ = this.store.select(selectProfil);
  loading$ = this.store.select(selectProfilLoading);
  error$ = this.store.select(selectProfilError);

  // FIXME: loading$ und error$ - werden diese angezeigt?
  telecontrols$ = this.store.select(selectTelecontrols);
  loadingTelecontrols$ = this.store.select(selectTelecontrolsLoading);
  errorTelecontrols$ = this.store.select(selectTelecontrolsError);

  isManager$ = this.store.select(selectProfilRoles(['manager']));
  isService$ = this.store.select(selectProfilRoles(['service']));
  risksOnline$ = this.risksService.isOnline$;
  risksInSync$ = this.risksService.isInSync$;

  constructor(
    private store: Store,
    private authService: AuthService,
    private socketImportService: SocketImportService,
    private socketMqttMessagesService: MqttMessagesSocketService,
    private socketMqttPresetsService: SocketMqttPresetsService,
    private mqttMessagesDataService: MqttMessagesDataService,
    private mqttMessagesService: MqttMessagesService,
    private mqttPresetsService: MqttPresetsService,
    private layoutService: LayoutService,
    private statusService: StatusService,
    private risksService: AssessmentStoreService,
    private risksSyncService: AssessmentSyncService,
    private risksSocketService: AssesmentSocketService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    // socketImportService
    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.socketImportService.connectClientSession(profil.Uid);
      }
    });

    this.profil$.subscribe((profil) => {
      if (
        profil?.Features?.Risks &&
        this.risksService.isOnline$.value &&
        profil.Features.RisksLocations.length > 0
      ) {
        AssessmentStoreService.currentUserLocations.next(
          profil.Features.RisksLocations,
        );
      }
    });

    // loadTelecontrols
    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.store.dispatch(TelecontrolActions.loadTelecontrols());

        this.telecontrols$.subscribe((telecontrols) => {
          this.fernwirktechnik = false;

          if (telecontrols.length > 0) {
            this.fernwirktechnik = true;
          }

          telecontrols.forEach((telecontrol) => {
            this.socketMqttMessagesService.connectClientSession(
              telecontrol.MQTT.Identifier,
            );

            this.mqttMessagesService.updateMessagesFromDb(
              telecontrol.MQTT.Identifier,
            );

            this.socketMqttPresetsService.connectClientSession(
              telecontrol.MQTT.Identifier,
            );

            this.mqttPresetsService.updatePresetsFromDb(
              telecontrol.MQTT.Identifier,
            );
          });

          // telecontrols.forEach((telecontrol) => {
          //   this.mqttMessagesDataService
          //     .getWarnObservable(telecontrol.MQTT.Identifier)
          //     .subscribe((message) => {
          //       this.warnMessages[telecontrol.MQTT.Identifier] = message;
          //     });
          // });
        });
      }
    });

    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });

    this.mqttMessagesDataService
      .getTotalInfoObservable()
      .subscribe((messages) => {
        this.info = messages;
      });

    this.mqttMessagesDataService
      .getTotalWarnObservable()
      .subscribe((messages) => {
        this.warn = messages;
      });

    this.mqttMessagesDataService
      .getTotalAlarmObservable()
      .subscribe((messages) => {
        this.alarm = messages;
      });

    // risksSyncService
    this.profil$.subscribe((profil) => {
      if (profil?.Features?.Risks && this.risksService.isOnline$.value) {
        if (this.risksSyncService.offlineUpdates()) {
          this.risksSyncService.startSynchronisation();
        }
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        if (this.risksSyncService.isOnline$.value)
          this.risksSocketService.connectClientSession(profil);
        else this.risksSocketService.closeClientSession();
      }
    });
  }

  onLogout() {
    this.authService.logout();
    this.socketImportService.closeClientSession();
    this.socketMqttMessagesService.closeClientSession();
    this.socketMqttPresetsService.closeClientSession();
    this.risksSocketService.closeClientSession();
    this.risksService.clearOfflineData();

    localStorage.clear();
  }

  onUpdate() {
    document.location.reload();
  }

  checkRiskSync() {
    if (this.risksSyncService.offlineUpdates())
      this.risksSyncService.startSynchronisation();
  }

  readThemeMode() {
    return this.layoutService.readThemeMode();
  }

  public onToggleTheme() {
    this.layoutService.toggleMode();
  }
}
