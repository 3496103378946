import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { AssessmentStoreService } from './assessment.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Template } from '../models/Template';
import { Cabinet } from '../models/Cabinet';
import { Evaluation } from '../models/Evaluation';
import { User } from '../models/User';
import { merge, reduce } from 'rxjs';
import { AssessmentSyncService } from './assesmentsync.service';
import { ImageEntry } from '../models/Image';

interface SocketExtended extends Socket {
  user?: User;
}

@Injectable({
  providedIn: 'root',
})
export class AssesmentSocketService {
  // socket: any = null;
  socket: SocketExtended | null = null;

  constructor(
    private offlineDb: NgxIndexedDBService,
    private syncService: AssessmentSyncService,
  ) {}

  public connectClientSession(user: User) {
    // return; // TODO : ACHTUNG !! KEIN COMMIT !!! MUSS RÜCKGÄNGIG GEMACHT WERDEN !!!
    // Wenn die aktuelle Socket-Verbindung aktiv ist und der `user` gleich ist, mache nichts
    if (
      this.socket &&
      this.socket.connected &&
      this.socket.user?.Uid === user.Uid
    ) {
      return;
    }
    // Falls die Verbindung aktiv ist, aber der `uid` sich ändert, schließe die alte Verbindung
    if (this.socket && this.socket?.user?.Uid !== user.Uid) {
      this.closeClientSession();
    }
    // Neue Socket-Verbindung aufbauen
    this.socket = io(`${environment.risks_Url}/user`, {
      path: '/wss/store-risk',
    });

    (this.socket as any).user = user;

    // Wird ausgelöst, wenn die Verbindung zum Server hergestellt wird.
    this.socket.on('connect', () => {
      if (this.socket) {
        this.socket.emit('user', user);
        console.log(`Socket: user [${user.Uid}] - Status [connected]`);
      }
    });

    // -------------- Templates --------------------- //

    // update template
    this.socket.on('template', (template: Template) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb.update('Template', template).subscribe((offline) => {
          console.log('postTemplate() result from off-DB:', offline);
          if (offline)
            AssessmentStoreService.currentTemplateSubject.next(offline);
        });
        console.log(
          `Risk-Socket: update Template [${template.Uid}] - ${new Date().toISOString()}`,
        );
      }
    });

    // -------------- Cabinets ---------------------- //

    // update cabinet(s)
    this.socket.on('cabinet', (cabinet: Cabinet) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb.update('Cabinet', cabinet).subscribe((offline) => {
          console.log('postCabinet() result from off-DB:', offline);
          const currentCabinet =
            AssessmentStoreService.currentCabinetSubject.value;
          if (offline && currentCabinet && offline._id == currentCabinet._id)
            AssessmentStoreService.currentCabinetSubject.next(offline);

          const res1 = this.offlineDb.getAll<Cabinet>('Cabinet');
          const res2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
          const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
          res.subscribe((offlineCabinets) => {
            AssessmentStoreService.currentCabinetsSubject.next(offlineCabinets);
          });
        });
        console.log(
          `Risk-Socket: update Cabinet [${cabinet.Uid}] - ${new Date().toISOString()}`,
        );
      }
    });

    // update cabinet(s)
    this.socket.on('delcabinet', (cabinet: Cabinet) => {
      if (!this.syncService.offlineUpdates()) {
        const idxCab = AssessmentStoreService.currentCabinetsSubject.value.find(
          (offs) => offs._id === cabinet._id,
        );
        if (idxCab) {
          const index =
            AssessmentStoreService.currentCabinetsSubject.value.indexOf(idxCab);
          AssessmentStoreService.currentCabinetsSubject.value.splice(index, 1);
        }
        this.offlineDb.deleteByKey('Cabinet', cabinet._id).subscribe();
      }
    });

    // update cabinets
    this.socket.on('cabinets', (cabinets: Cabinet[]) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb.update('Cabinet', cabinets).subscribe((offline) => {
          console.log('postCabinet() result from off-DB:', offline);
          AssessmentStoreService.currentCabinetsSubject.next(offline);
        });
        console.log(
          `Risk-Socket: update Cabinet count:[${cabinets.length}] - ${new Date().toISOString()}`,
        );
      }
    });

    // update cabinets after some was deleted
    this.socket.on('cabinetsPostOnDel', (cabinets: Cabinet[]) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb.clear('Cabinet');
        this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
        AssessmentStoreService.currentCabinetsSubject.next(cabinets);

        const currentCabinet =
          AssessmentStoreService.currentCabinetSubject.value;
        const offline = cabinets.find(
          (c) => currentCabinet && currentCabinet._id == c._id,
        );
        if (offline && currentCabinet && offline._id == currentCabinet._id)
          AssessmentStoreService.currentCabinetSubject.next({} as Cabinet);
        console.log(
          `Risk-Socket: ${cabinets.length} Cabinets after delete one - ${new Date().toISOString()}`,
        );
      }
    });

    // -------------- Evaluations ------------------- //

    // update evaluation(s)
    this.socket.on('evaluation', (evaluation: Evaluation) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb.update('Evaluation', evaluation).subscribe((offline) => {
          console.log('postEvaluation() result from off-DB:', offline);
          let cabinetId: string | null = evaluation.Cabinet._id;
          const currentEvaluation =
            AssessmentStoreService.currentEvaluationSubject.value;
          if (
            offline &&
            currentEvaluation &&
            offline._id == currentEvaluation._id
          ) {
            AssessmentStoreService.currentEvaluationSubject.next(offline);
          }
          const res1 = this.offlineDb.getAll<Evaluation>('Evaluation');
          const res2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
          const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
          res.subscribe((offlineEvaluations) => {
            const currentEvaluations = offlineEvaluations.filter(
              (e) => e.Cabinet?._id == cabinetId,
            );
            AssessmentStoreService.currentEvaluationsSubject.next(
              currentEvaluations,
            );
          });
        });
        console.log(
          `Risk-Socket: update Evaluation [${evaluation._id}] - ${new Date().toISOString()}`,
        );
      }
    });

    // update evaluations
    this.socket.on('evaluations', (evaluations: Evaluation[]) => {
      if (!this.syncService.offlineUpdates()) {
        this.offlineDb
          .update('Evaluation', evaluations)
          .subscribe((offline) => {
            console.log('postEvaluation() result from off-DB:', offline);
            const currentEvaluations: Evaluation[] = [];
            offline.map((e) => {
              if (
                AssessmentStoreService.currentCabinetSubject.value?._id &&
                e &&
                e.Cabinet?._id ===
                  AssessmentStoreService.currentCabinetSubject.value?._id
              ) {
                currentEvaluations.push(e);
              }
            });
            if (currentEvaluations.length > 0)
              AssessmentStoreService.currentEvaluationsSubject.next(
                currentEvaluations,
              );
          });
        console.log(
          `Risk-Socket: update Evaluation count:[${evaluations.length}] - ${new Date().toISOString()}`,
        );
      }
    });

    this.socket.on('delevaluation', (evaluatoin: Evaluation) => {
      // if (AssessmentStoreService.currentEvaluationsSubject.value.map((e) => e._id).includes(evaluatoin._id)) {
      if (!this.syncService.offlineUpdates()) {
        const idxEva =
          AssessmentStoreService.currentEvaluationsSubject.value.find(
            (offs) => offs._id === evaluatoin._id,
          );
        if (idxEva) {
          const index =
            AssessmentStoreService.currentEvaluationsSubject.value.indexOf(
              idxEva,
            );
          AssessmentStoreService.currentEvaluationsSubject.value.splice(
            index,
            1,
          );
        }
        this.offlineDb.deleteByKey('Evaluation', evaluatoin._id).subscribe();
      }
    });

    // -------------- Images ------------------------ //

    // update Image
    this.socket.on('image', (imgEntry: any) => {
      if (!this.syncService.offlineUpdates()) {
        console.info('Risk-Socket: update Image');
        if (imgEntry) {
          console.info('Risk-Socket: update Image', imgEntry);
          try {
            this.offlineDb
              .update<ImageEntry>('Image', {
                _id: imgEntry.id,
                imgFile: imgEntry,
              } as ImageEntry)
              .subscribe((offlineRes) => {
                if (offlineRes) {
                  console.log(
                    `Risk-Socket: update Image [${imgEntry.id}] - ${new Date().toISOString()}`,
                  );
                } else {
                  console.info(
                    'Risk-Socket: update Image didn`t write to offline-db',
                  );
                }
              });
          } catch (err) {
            console.error('Risk-Socket: update Image ERROR:', err);
          }
        }
      }
    });

    // ---------------------------------------------- //

    // Wird ausgelöst, wenn die Verbindung zum Server getrennt wird.
    this.socket.on('disconnect', () => {
      console.log(`Socket: Uid [${user}] - Status [disconnected]`);
    });
    // Tritt auf, wenn ein Fehler während des Verbindungsversuchs auftritt.
    this.socket.on('connect_error', (err: any) => {
      console.log(
        `Socket: Uid [${user}] - Status [error] - name [${err.name}] - message [${err.message}]`,
      );
    });
    // Tritt auf, wenn die Verbindung nicht innerhalb des festgelegten Zeitlimits hergestellt werden kann.
    this.socket.on('connect_timeout', () => {
      console.log(`Socket: Uid [${user}] - Status [timeout]`);
    });
    // Wird ausgelöst, wenn der Client versucht, sich erneut mit dem Server zu verbinden.
    this.socket.on('reconnect_attempt', () => {
      console.log(`Socket: Uid [${user}] - Status [reconnecting]`);
    });
    // Wird ausgelöst, wenn ein erneuter Verbindungsversuch fehlschlägt.
    this.socket.on('reconnect_failed', () => {
      console.log(`Socket: Uid [${user}] - Status [failed]`);
    });
    // Wird ausgelöst, wenn ein Fehler während des Reconnect-Versuchs auftritt.
    this.socket.on('reconnect_error', () => {
      console.log(`Socket: Uid [${user}] - Status [error]`);
    });
    // Wird ausgelöst, wenn die Verbindung wiederhergestellt wird.
    this.socket.on('reconnect', () => {
      console.log(`Socket: Uid [${user}] - Status [reconnecting]`);
    });
  }

  public closeClientSession = () => {
    if (this.socket != null) {
      this.socket.disconnect();
      this.socket.close();
    }
  };
}
